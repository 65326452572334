.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.nopadding td, .borderless th {
  padding: 0;
}

.no-border {
  border: 0px;
}

.sign-out {
  text-align: center !important;
}

.login-menu {
  min-width: 100% !important;
}

.bg-gray {
  background-color: #8a8a8a;
}

input:disabled {
   opacity: 1;
}

:root {
  --bs-border-color: black !important;
}

.aspirant-options{
  margin-top:2rem;
  margin-bottom:10rem;
  padding: 9rem;
  border-radius: 100px;
}

.aspirant-input {
  width: 45%;
  min-width: 100px;
  max-width: 350px;
}

.posso-right {
  float: right !important; 
  clear: both !important;
}

.aspirant-profile-chat {
  margin-right: 75% !important;
}

.text-black {
  color: black !important;
}

.organization-add-job {
  border: 0;
}

.aspirant-interview-link:hover {
  background-color: white;
}

.aspirant-interview-link:active {
  background-color: white;
}

.interview-how-button {
  border: none;
  background-color: transparent;
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.15em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.ag-header-row {
  border-top: solid;
  border-bottom: solid;
  border-width: 1px;
  max-height: 40px;
  font-size: 14px !important;
}

.ag-row {
  max-height: 40px;
  font-size: 14px !important;
  text-align: left;
  font-weight: normal;  
}

.navbar {
  box-shadow: 0px 1.9px 2.2px rgba(0, 0, 0, 0.02), 0px 4.5px 5.3px rgba(0, 0, 0, 0.05);
  border-bottom: none !important;
}

.link-primary:hover {
  color: #7300E6 !important
}

.link-primary:active {
  color: #7300E6 !important
}

.job-info-divider {
  border-color: #000000 !important;
}

.ag-cell-focus,.ag-cell-no-focus{
  border:none !important;
}

.no-border-grid.ag-cell:focus{
  border:none !important;
  outline: none;
}

.fb_selected {
  background-color: #7300E6 !important;
  color: white !important;
}

.row {
  margin-left: auto !important;
}

label[for='ui-sign-in-email-input']{
  display: none;
}

.accordion-item {
  border: 0 !important;
}  

.line-divide {
  border-color: #000000;
  margin-top: 2px;
  margin-bottom: 2px;
}

.header-divide {
  border-color: #000000;
  margin-top: 3px;
  margin-bottom: 3px;
  border-width: 2px;
}

.seed-question-links {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #0086ec;
  text-decoration: underline;
  cursor: pointer;
}

.seed-question-links:hover {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #006fc4;
  text-decoration: underline;
  cursor: pointer;
}

.seed-question-links:active {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #005da4;
  text-decoration: underline;
  cursor: pointer;
}

.PhoneInputInput {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 0.9rem;
  border-color: #999999;
  font-weight: 600;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #999999;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.interview-report-row {
  margin-left: calc(-.5 * var(--bs-gutter-x)) !important;
}

.interview-report-h2 {
  font-weight: 500 !important;

}

.z-index-top {
  z-index: 6;
}

#bg-black {
  background-color: #000000 !important;

}

.thin-footer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.list-display li {
  margin-bottom:.4rem;
  font-size:1.6rem;
}
.list-checkmarks {
  padding-left:2rem;
}
.list-checkmarks li {
  list-style-type:none;
  padding-left:1rem;
}
  /* .list-checkmarks li:before {    
  font-family: 'FontAwesome';
  content: "\f00c";
  margin:0 10px 0 -28px;
  color: #ffffff;
  } */

  .row-flex {
    display: flex;
    flex-wrap: wrap;
  }

.underline {
  display: inline-block;
  border-bottom: 5px solid #6600ff;
}

.btn.mockinterviewbtn{
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .header-section {
    height: 60px; /* Adjust to your header's height */
  }
  .content-start {
    margin-top: 60px !important; /* Add space for the header */
  }
}

.mkt-top-nav {
  color: unset !important;
  padding: unset !important;
  text-decoration: underline !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  min-width: unset !important;
  margin-right: unset !important;
  background-color: #000000 !important;
  border: none !important;
}

@media(max-width: 992px) {
  .mkt-top-nav{
    text-align: right !important;
    padding-right: 10px !important;
  }
}
@media(min-width: 1400px) {
  .mkt-top-nav{
    min-height: unset !important;
  }
}

.mkt-feature-menu {
  font-size: unset !important;
}
